<template>
    <div class="propus-image v-responsive v-img" :class="rounded_class">
           <div class="v-responsive__sizer" style="padding-bottom: 100%;">
           </div>
           
           <div v-if="$slots.placeholder && !is_image_ready" class="v-img__placeholder">
                <slot name="placeholder" />
           </div>

            <img 
                v-else
                :src="src"
                :class="[imgClass, {'d-none': !is_image_ready}]"
                class="v-img__img v-img__img--cover"
                :loading="lazySrc"
                @error="is_image_working = false"
            />

            <div class="v-responsive__content">
                <slot />
            </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
const props = defineProps({
    src: String,
    lazySrc: String,
    imgClass: {
        type: String,
        default: "",
    },
    rounded: {
        type: String,
        default: "",
    },
    width: {
        type: [String, Number],
        default: "",
    }
})

const is_image_working = ref(true)

const rounded_class = computed(() => props.rounded ? `rounded-${props.rounded}`: "")
const width = computed(() => props.width ? `${props.width}px`: "")
const is_image_ready = computed(() => is_image_working.value && props.src)
</script>

<style lang="scss" scoped>
$width: v-bind(width);

.propus-image {
    z-index: 1;
    width: $width;
}
</style>